<template>
  <div class="wrapper">
    <div class="bgImg">
      <img
          :src="jobFairDetailList.bannerUrl"
          alt
          class="err_image"
          v-if="jobFairDetailList.bannerUrl"
      />
    </div>
    <header>
      <div class="banner_top flex">
        <div class="w1200">
          <ul class="lefts flex">
            <li>
              <span>{{ jobFairStatics.companyCount }}</span
              >企业
            </li>
            <li>
              <span>{{ jobFairStatics.jobCount }}</span
              >职位
            </li>
          </ul>
          <p class="rights">
            <span>招聘时间：</span>
            {{ jobFairDetailList.sdate }}—{{ jobFairDetailList.edate }}
          </p>
        </div>
      </div>
    </header>
    <section class="s1">
      <div class="partSearch" v-if="isShowsGw">
        <el-input
            :placeholder="'请输入'+(active == 0?'职位/公司':'公司')+'名称'"
            v-model="value"
            class="width100"
            @input="search"
        >
          <template slot="append">
            <el-button icon="el-icon-search" @click="search" type="primary"
            >搜索
            </el-button
            >
          </template>
        </el-input>
        <!-- <div class="radios relative flex"  v-if="isShowsGkk">
          <div class="color66 span_name">公开课：</div>
          <el-radio-group v-model="page.publicActive" class="world">
            <ul class="top flex-wrap" >
              <li class>
                <el-radio label>不限</el-radio>
              </li>
              <li v-for="(n, i) in publicTab" :key="i"  >
                <el-radio :label="n.value">{{ n.label }}</el-radio>
              </li>
            </ul>
          </el-radio-group>
        </div> -->
        <!-- <div class="flex first w12" v-if="isShowsGkk">
          <p class="area">公开课:</p>
          <div v-if="isFooter">
            <selectCity class="city" @getVal="setCityValue" :isLocation="false"  v-if="isShow"></selectCity>
            <div v-else>
              <ul class="city_id flex-wrap">
                <li
                    :class="{ active: publicActive == 0 }"
                    class="li"
              @click="publicTabs(0)"
                >
                  全部
                </li>
                <li
                        v-for="(item, index) in publicTab"
                        :class="{ active: publicActive == index + 1 }"
                        :key="index"
                class="li"
                        @click="publicTabs(index + 1, item.value)"
                        v-if="index < 9"
                >
                    <a href="javascript:void(0);" :title="item.label">{{
                        item.label
                        }}</a>
                </li>
              </ul>
            </div>
          </div>
          </div> -->

        <!-- <div class="content1">
        <div class="flex first w12" v-if="isShowsGkk">
            <h1>公开课</h1>
            <ul class="city_id flex-wrap">
                <li :class="{ active: publicActive == 0 }" @click="publicTabs(0)">
                    全部
                </li>
                <li
                        v-for="(item, index) in publicTab"
                        :class="{ active: publicActive == index + 1 }"
                        :key="index"
                        @click="publicTabs(index + 1, item.value)"
                        v-if="index < 9"
                >
                    <a href="javascript:void(0);" :title="item.label">{{
                        item.label
                        }}</a>
                </li>
            </ul>
        </div>
        </div> -->
      </div>
      <div class="flex first w12" v-if="isShows">
        <p class="area">选择地区:</p>
        <div v-if="isFooter">
          <selectCity class="city" @getVal="setCityValue" :isLocation="false" v-if="isShow"></selectCity>
          <div v-else>
            <ul class="city_id flex-wrap">
              <li
                  :class="{ active: cityActive == 0 }"
                  @click="tabCity(0, '')"
                  class="li"
              >
                全部
              </li>
              <li
                  v-for="(item, index) in cityList"
                  :key="index"
                  :class="{ active: cityActive == index + 1 }"
                  class="li"
                  @click="tabCity(index + 1, item.value, item.children)"
              >
                {{ item.label }}
              </li>
            </ul>
            <el-collapse-transition>
              <div class="child flex" v-show="childList.length > 0">
                <div class="child_list flex-wrap">
                  <div
                      class="all"
                      :class="{ active: childActive == 0 }"
                      @click="tabChild(0)"
                  >
                    不限
                  </div>
                  <ul v-for="(item, index) in childList" :key="index">
                    <li
                        :class="{ active: childActive == index + 1 }"
                        @click="tabChild(index + 1, item.itemValue)"
                    >
                      {{ item.label }}
                    </li>
                  </ul>
                </div>
              </div>
            </el-collapse-transition>
          </div>
        </div>
        <el-cascader
            v-model="params.cityid"
            :options="cityOption"
            :props="{ checkStrictly: true }"
            class="selected"
            popper-class="selecteds"
            v-if="!isFooter"
            @change="setScasDisTypeValue"></el-cascader>
      </div>
      <div class="flex second w12" v-if="isShows">
        <p class="area">残疾类别:</p>
        <ul class="flex join" v-if="isFooter">
          <li
              class="flexs"
              v-for="(item, index) in tabList"
              :key="index"
              :class="{ actives: actives == index }"
              @click="tabLi(index, item.value)"
          >
            {{ item.label }}
          </li>
        </ul>
        <el-select
            v-model="params.disType"
            class="selects"
            placeholder="残疾类型"
            popper-class="select_link"
            v-else
        >
          <el-option
              :label="n.label"
              :value="n.value"
              v-for="(n, i) in tabList"
              :key="i"
          >{{ n.ITEM_TEXT }}
          </el-option>
        </el-select>
      </div>
      <div class="radios relative flex" v-if="isShows">
        <div class="color66 span_name">
          <pre>行      业:</pre>
        </div>
        <el-radio-group v-model="params.hyTop" class="world">
          <ul class="top flex flex-wrap">
            <li
                class
                @click="
                  hyShow = false,
                  params.hy = ''
                "
            >
              <el-radio label>不限</el-radio>
            </li>
            <li
                v-for="(n, i) in industryType"
                :key="i"
                @click="industryChild(n.children)"
            >
              <el-radio :label="n.value">{{ n.label }}</el-radio>
            </li>
          </ul>
          <el-collapse-transition>
            <el-radio-group
                v-model="params.hy"
                v-show="hyShow"
                class="collapse"
            >
              <ul class="bottom flex flex-wrap" ref="getHeight">
                <li>
                  <el-radio label>不限</el-radio>
                </li>
                <li v-for="(m, s) in industryTypes" :key="s">
                  <el-radio :label="m.value">{{ m.label }}</el-radio>
                </li>
              </ul>
            </el-radio-group>
          </el-collapse-transition>
        </el-radio-group>
      </div>
      <div class="radios relative flex" v-if="isShows">
        <div class="color66 span_name">月薪范围：</div>
        <el-radio-group v-model="params.jobSalary" class="world">
          <ul class="top flex-wrap">
            <li class>
              <el-radio label>不限</el-radio>
            </li>
            <li v-for="(n, i) in positionMoney" :key="i">
              <el-radio :label="n.ITEM_VALUE">{{ n.ITEM_TEXT }}</el-radio>
            </li>
          </ul>
        </el-radio-group>
      </div>
      <div class="radios relative flex" v-if="isShows">
        <div class="color66 span_name">公司性质：</div>
        <el-radio-group v-model="params.pr" class="world">
          <ul class="top flex-wrap">
            <li class>
              <el-radio label>不限</el-radio>
            </li>
            <li v-for="(n, i) in companyType" :key="i">
              <el-radio :label="n.ITEM_VALUE">{{ n.ITEM_TEXT }}</el-radio>
            </li>
          </ul>
        </el-radio-group>
      </div>
    </section>
    <section
        class="s2"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
    >
      <ul class="flexs titls">
        <li
            class="cursorP"
            :class="{ active: active == index }"
            @click="tabs(index)"
            v-for="(item, index) in tab"
            :key="index"
        >
          {{ item }}
        </li>
      </ul>
      <div
          class="marT50"
          v-if="active == 1"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
      >
        <ul class="flex uls">
          <li
              v-for="(item, index) in jobFairCompanysList"
              :key="index"
              class="lis"
          >
            <div class="wrap" v-if="item.companyInfo">
              <div class="box flex">
                <div class="flexs">
                  <!-- <div class="imgs flexs">
                    <img
                      :src="item.companyInfo.logo"
                      alt
                   
                      v-if="item.companyInfo.logo"
                    />
                  </div> -->
                  <div class="doc">
                    <h3 class="line_clamp1">
                      <router-link
                          :to="
                          '/job/businessDetails/?companyId=' +
                            item.companyInfo.id
                        "
                          target="_blank"
                      >{{ item.companyInfo.companyName }}
                      </router-link
                      >
                    </h3>
                    <p class="line_clamp1">
                      {{ item.companyInfo.hyTop }}/{{ item.companyInfo.pr }}/{{
                        item.companyInfo.mun
                      }}
                    </p>
                  </div>
                </div>

                <p class="more cursorP"></p>
              </div>
              <div class="box_2 flex">
                <ul class="u1">
                  <li
                      class="line_clamp1"
                      v-for="item in item.jobs"
                      :key="item.jobID"
                  >
                    <router-link
                        :to="'/job/jobDetail/?id=' + item.jobId"
                        target="_blank"
                    >{{ item.jobName }}
                    </router-link
                    >
                  </li>
                </ul>
                <ul class="u2">
                  <li v-for="item in item.jobs" :key="item.jobID">
                    {{ item.salary }}
                  </li>
                </ul>
              </div>
              <div class="box_3 flexs">
                <el-button
                    type="primary"
                    round
                    @click="goRouter('/job/businessDetails/', item.companyInfo.id, 2)"
                >查看全部职位
                </el-button
                >
              </div>
            </div>
          </li>
        </ul>
        <div class="pagination flexs">
          <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              class="work_pagination"
              :current-page.sync="params.pageNum"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="params.pageSize"
              layout="total,prev, pager, next"
              prev-text="上一页"
              next-text="下一页"
              :total="total"
          ></el-pagination>
        </div>
        <div class="marT50">
          <status
              type="post"
              des="未搜索到相关信息，再看看别的吧"
              v-if="status.isHide == 1"
          ></status>
        </div>
      </div>
      <div
          class="marT50"
          v-if="active == 0"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
      >
        <ul class="flex uls">
          <li v-for="item in jobFairJobsList" :key="item.id" class="lis">
            <div class="wrap_2">
              <div class="box_1">
                <div class="container_1 flex">
                  <div class="flex">
                    <h3 class="line_clamp1">
                      <router-link
                          :to="'/job/jobDetail/?id=' + item.jobId"
                          target="_blank"
                      >{{ item.jobName }}
                      </router-link
                      >
                    </h3>

                    <!--                    <small>「{{item.createDate}}参会」</small>-->
                    <p
                        class="p1 marL5"
                        :class="[
                        item.type == '1' ? 'p1_canlian' : '',
                        item.type == '2' ? 'p1_zhilian' : '',
                        item.type == '3' ? 'p1_edu' : '',
                      ]"
                    >
                      {{ item.type | getIsGetOns(item.type) }}
                    </p>
                  </div>
                  <p class="p2">{{ item.salary }}</p>
                </div>
                <ul class="container_2 flex line_clamp1">
                  <li>
                    {{ item.cityid }}
                    <el-divider direction="vertical"></el-divider>
                  </li>
                  <li>
                    {{ item.edu }}
                    <el-divider direction="vertical"></el-divider>
                  </li>
                  <li>
                    {{ item.exp }}
                    <el-divider direction="vertical"></el-divider>
                  </li>
                  <li>多种残疾/不限</li>
                </ul>
                <ul class="container_3 line_clamp1" v-if="item.jobLabels.leng > 0">
                  <li v-for="i in item.jobLabels" :key="i">
                    {{ i.labelName }}
                  </li>
                  <li v-if="i.jobLabels <= 0">暂无福利标签</li>
                </ul>
              </div>
              <div class="box_2 flex" v-if="item.companyInfo">
                <div class="imgs flexs" v-if="item.companyInfo.logo">
                  <img
                      :src="item.companyInfo.logo"
                      alt
                  />
                </div>
                <div class="doxs">
                  <h3>
                    <router-link
                        :to="
                        '/job/businessDetails/?companyId=' + item.companyInfo.id
                      "
                        target="_blank"
                    >{{ item.companyInfo.companyName }}
                    </router-link
                    >
                  </h3>
                  <ul class="content line_clamp1">
                    <li v-if="item.companyInfo.hyTop">
                      {{ item.companyInfo.hyTop }}
                    </li>
                    <li v-else>
                      暂无
                    </li>
                    <li>
                      <el-divider direction="vertical"></el-divider>
                      {{ item.companyInfo.mun }}
                    </li>
                    <li>
                      <el-divider direction="vertical"></el-divider>
                      {{ item.companyInfo.pr }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="pagination flexs">
          <el-pagination
              background
              @size-change="handleSizeChangeone"
              @current-change="handleCurrentChangeone"
              class="work_pagination"
              :current-page.sync="params.pageNum"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="params.pageSize"
              layout="total,prev, pager, next"
              prev-text="上一页"
              next-text="下一页"
              :total="params.total"
          ></el-pagination>
        </div>
        <div class="marT50">
          <status
              type="post"
              des="未搜索到相关信息，再看看别的吧"
              v-if="status.isHide == 1"
          ></status>
        </div>
      </div>
      <div
          class="marT50"
          v-if="active == 2"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
      >
        <ul class="public_list  flex flex-wrap">
          <li v-for="(n,i) in publicList" :key="i" class="box" @click="goRouter1('/training/publicDetail',n.id,'train')">
            <router-link :to="'/training/publicDetail?id='+n.id" target="_blank" :title="n.trainName" class="name">
              <div class="top relative">
                <img :src="n.imgurl" alt="" class="banner err_image">
              </div>
              <div class="bottom marT10">
                {{ n.trainName }}
                <div class="address">
                  <ul>
                    <li>
                      <span>共{{ n.reamark }}章节</span>
                    </li>

                  </ul>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
        <div class="box_3 flexs">
          <el-button
              type="primary"
              round
              @click="$router.push('/training/public')"
          >查看全部公开课
          </el-button
          >
        </div>
        <div class="marT50">
          <status
              type="post"
              des="未搜索到相关信息，再看看别的吧"
              v-if="status.isHide == 2"
          ></status>
        </div>
        <!-- 公开课分页 -->
        <div class="pagination flexs">
          <el-pagination
              background
              @current-change="gkk"
              class="work_pagination"
              :current-page.sync="page.pageNum"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="page.pageSize"
              layout="total,prev, pager, next"
              prev-text="上一页"
              next-text="下一页"
              :total="page.total"
          ></el-pagination>
        </div>
      </div>
      <div
          class="marT50"
          v-if="active == 3"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
      >
        <guidance></guidance>
      </div>
    </section>
    <div class="others">
      <ul>
        <li v-show="jobFairDetailList.qhUrl">
          <a :href="jobFairDetailList.qhUrl" target="_blank">
            <img src="@/static/qhbanner.png" alt="青海招聘会">
          </a>
        </li>
        <li v-show="jobFairDetailList.lzUrl">
          <a :href="jobFairDetailList.lzUrl" target="_blank">
            <img src="@/static/lzbanner.png" alt="兰州招聘会">
          </a>
        </li>
        <li v-show="jobFairDetailList.sxUrl">
          <a :href="jobFairDetailList.sxUrl" target="_blank">
            <img src="@/static/sxbanner.png" alt="陕西招聘会">
          </a>
        </li>
        <li v-show="jobFairDetailList.szUrl">
          <a :href="jobFairDetailList.szUrl" target="_blank">
            <img src="@/static/szbanner.png" alt="深圳招聘会">
          </a>
        </li>
      </ul>
    </div>
    <Footer v-if="isFooter"></Footer>
    <div class="fixed" @click="isCompany">
      <img src="@/static/fuchuang.png" alt="">
    </div>
    <company-sign :show="isSign" :id="$route.query.id" @close="isSign = false"></company-sign>
    <login-prompt :goShow="isHide" @close="isHide = false" title="企业报名" text="登录后才可以进行企业报名"></login-prompt>
  </div>
</template>
<script>
import companySign from "components/public/companySign";
import Footer from "components/public/Footer";
import selectCity from "components/search/selectCity";
import ThreeLink from "@/components/selectComponents/ThreeLink";
import loginPrompt from '@/components/public/loginPrompt'
import guidance from '@/components/news/guidance'

export default {
  name: "videoJob",
  components: {Footer, selectCity, companySign, ThreeLink, loginPrompt, guidance},
  data() {
    return {
      loading: false,
      publicTab: [],
      tabList: [
        {label: "全部", value: ""},
        {label: "视力残疾", value: "1"},
        {label: "听力残疾", value: "2"},
        {label: "言语残疾", value: "3"},
        {label: "肢体残疾", value: "4"},
        {label: "智力残疾", value: "5"},
        {label: "精神残疾", value: "6"},
        {label: "多重残疾", value: "80"},
      ],
      tab: ["职位展示", "企业展示", "公开课", "就业指导"],
      active: 0,
      actives: 0,
      value: "",
      jobFairDetailList: [],
      jobFairCompanysList: [],
      jobFairJobsList: [],
      jobFairStatics: {},
      logosList: [{status: 2}],
      total: 0,
      params: {
        id: "",
        name: "",
        pageNum: 1,
        pageSize: 9,
        cityid: "",
        threeCityid: "",
        disType: "",
        hyTop: '',
        hy: '',
        jobSalary: '',
        pr: '',
      },
      hyShow: false,
      paramsone: {
        id: "",
        name: "",
        pageNum: 1,
        pageSize: 9,
        cityid: "",
        threeCityid: "",
        disType: "",
        total: 0,
        hyTop: '',
        hy: '',
        jobSalary: '',
        isShow: false
      },
      page: {
        //publicActive: '',
        projectType:'1',
        pageNum: 1,
        pageSize: 8,
        total: 0,
      },
      status: {
        isHide: 0,
        type: "",
        des: "",
      },
      isShow: false,
      isShows: true,
      isShowsGkk: false,
      isShowsGW: false,
      isSign: false,
      cityList: [],
      childList: [],
      cityOption: [],
      cityActive: 0,
      childActive: 0,
      isLogin: '',
      isHide: false,
      isFooter: true,
      publicList: [],
      newsList: [],
      industryType: [],
      industryTypes: [],
      positionMoney: [],
      companyType: [],
    };
  },
  filters: {
    getIsGetOns(key) {
      let type = "";
      switch (key) {
        case "1":
          type = "中国残联";
          break;
        case "2":
          type = "智联";
          break;
        case "3":
          type = "教育部";
          break;
      }
      return type;
    },
  },
  watch: {
    params: {
      handler(newName, oldName) {
        this.jobFairJobs();
      },
      deep: true,
    },
  },
  methods: {
    search() {
      this.params.name = this.value;
      this.jobFairCompanys();
    },
    publicTabs(i, val) {
      this.page.publicActive = i;
      this.publicApi(val);
    },
    tabLi(index, val) {
      this.actives = index;
      this.params.disType = val;
    },
    setScasDisTypeValue(val) {
      this.params.cityid = val[0];
      if (val[1]) {
        this.params.threeCityid = val[1];
      }
    },
    setCityValue(val) {
      this.params.cityid = val[0];
      this.params.threeCityid = val[1];
      this.paramsone.cityid = val[0];
      this.paramsone.threeCityid = val[1];
    },
    tabs(index) {
      this.active = index;
      if (this.active == 1) {
        this.isShows = false;
        this.isShowsGkk = false;
        this.isShowsGw = true;
        this.jobFairCompanys();
      }
      if (this.active == 0) {
        this.isShows = true;
        this.isShowsGkk = true;
        this.isShowsGw = true;
        this.jobFairJobs(this.$route.query.id);
      }
      if (this.active == 2) {
        this.isShowsGkk = true;
        this.isShows = false;
        this.isShowsGw = false;
        this.publicApi();
      }
      let provinceId = this.$route.query.provinceid;
      let cityId = this.$route.query.cityid;
      let itemValue, code;
      if (provinceId) {
        itemValue = this.$route.query.provinceid;
        code = "cityId";
        if (cityId) {
          itemValue = this.$route.query.cityid;
          code = "cityId";
        }
        this.$api
            .jobFairCity({
              itemValue: itemValue,
              code: code,
            })
            .then((res) => {
              this.isShow = false;
              this.cityList = res.data.data;
            });
      } else {
        this.isShow = true;
      }
    },
    //企业报名
    isCompany() {
      if (this.isLogin) {
        this.isSign = true;
      } else {
        this.isHide = true;
      }
    },
    async jobFairDetail(id, name) {
      document.title = name || '专场招聘会';
      try {
        let res = await this.$api.jobFairDetail({id: id});
        if (res.data.success) {
          this.jobFairDetailList = res.data.data;
          this.jobFairStatics = this.jobFairDetailList.jobFairStatics;
        } else {
          this.$message.error(res.data.msg);
        }

        //城市筛选
        let res2 = await this.$axios.get(
            "/api/app-jycy-sysdict/commonDictValue",
            {dictCode: "cityid"})
        this.cityOption = res2.data.data;
        this.cityOption.unshift({label: '全部', value: ''})
      } catch (error) {
        console.log(error);
      }
    },
    async jobFairCompanys() {
      this.params.id = this.$route.query.id;
      try {
        let res = await this.$api.jobFairCompanys(this.params);
        this.loading = true;
        this.total = res.data.data.total;
        if (res.data.success) {
          this.jobFairCompanysList = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
          if (this.jobFairCompanysList.length <= 0) {
            this.status.isHide = 1;
            this.status.type = "post";
          } else {
            this.status.isHide = 0;
          }
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async jobFairJobs() {
      this.params.id = this.$route.query.id;
      this.loading = true;
      try {
        let res = await this.$api.jobFairJobs(this.params);
        if (res.data.success) {
          this.jobFairJobsList = res.data.data.records;
          this.params.total = res.data.data.total;
          this.loading = false;
          if (this.jobFairJobsList.length <= 0) {
            this.status.isHide = 1;
            this.status.type = "post";
          } else {
            this.status.isHide = 0;
          }
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //公开课
    async publicApi() {
      try {
        let res = await this.$api.publicListApi(this.page);
        this.loading = false;
        this.publicList = res.data.data.records;
        this.page.total = res.data.data.total;
        if (this.publicList.length <= 0) {
          this.status.isHide = 2;
          this.status.type = 'result';
        } else {
          this.status.isHide = '';
        }
      } catch (e) {
        this.loading = false;
        this.status.isHide = true;
        this.status.type = 'wifi';
      }
    },
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.jobFairCompanys();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.jobFairCompanys();
    },
    handleCurrentChangeone(val) {
      this.paramsone.pageNum = val;
      this.jobFairJobs(this.$route.query.id);
    },
    // 公开课(val) {
    //   this.paramsone.pageNum = val;
    //   this.page.pageNum = val;
    //   this.jobFairCompanys(this.$route.query.id);
    // },
    gkk(val) {
      this.page.pageNum = val;
      this.publicApi();
    },
    handleSizeChangeone(val) {
      this.paramsone.pageNum = val;
      this.page.pageNum = val;
      this.jobFairJobs(this.$route.query.id);
    },
    tabCity(i, val, child) {
      this.cityActive = i;
      this.params.cityid = val;
      this.childSelect(child);
    },


    childSelect(val) {
      if (val) {
        this.childList = val;
      } else {
        this.childList = [];
      }
    },
    tabChild(i, val) {
      this.childActive = i;
      this.params.threeCityid = val;
    },
    //公开课筛选项
    publicSelectList() {
      this.$api.selectListApi({dictCode: "open_train_class"}).then((res) => {
        if (res.data.success) {
          console.log(res.data.data);
          this.publicTab = res.data.data;
        }
      });
    },
    //行业
    industry() {
      let that = this;
      this.$api.industryApi().then((res) => {
        this.industryType = res.data.data;
      });
      this.$axios.get(
          "/api/app-jycy-sysdict/queryDictTextByDiCode", {code: "user_salary"}).then(res => {
        if (res.data.success) {
          this.positionMoney = res.data.data;
        }
      })
      this.$axios.get(
          "/api/app-jycy-sysdict/queryDictTextByDiCode", {code: "pr"}).then(res => {
        if (res.data.success) {
          this.companyType = res.data.data;
        }
      })

    },
    industryChild(items, value) {
      this.industryTypes = items;
      this.hyShow = true;
    },
    goRouter(url, id, active) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          companyId: id,
          active: active,
        },
      });
      window.open(href, "_blank");
      if (active == 'train') {
        this.$api.publicClickApi({id: id}).then(res => {
        })
      }
    },
    goRouter1(url, id, active) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id,
          active: active,
        },
      });
      window.open(href, "_blank");
    },
  },
  created() {
    this.jobFairDetail(this.$route.query.id, this.$route.query.name);
    this.tabs(this.$route.query.active || 0);
    this.industry();
    this.publicSelectList();
    this.isLogin =
        this.$store.state.userType ||
        JSON.parse(localStorage.getItem("userType"));
    if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      this.isFooter = false;
    } else {
      this.isFooter = true;
    }
  },
  mounted(){
        //判断是否移动端
    this.$mobile('https://lzmz.cdpee.org.cn/#/pages/index/jobfair/jobfair?id='+this.$route.query.id);
    // this.$mobile("https://lzmz.cdpee.org.cn/#/pages/index/jobList");

  },
};

</script>

<style lang="less" scoped>
@import "~assets/css/videoJob";

.radios {
  padding: 8px 0;
  width: 1200px;
  margin: 0 auto;

  .span_name {
    display: inline-block;
    width: 100px;
    font-size: 16px;

    pre {
      font: 16px/22px -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
    }
  }

  /deep/ .el-radio-group {
    width: 100%;
    // .is-checked .el-radio__label{
    // }
    .top {
      position: relative;

      li {
        margin-right: 6px;
      }
    }

    .top li {
      margin-bottom: 10px;
    }

    .collapsed {
    }

    .collapse {
      width: 92%;
      margin-left: 90px;
      background: #f8f8f8;

      .bottom {
        padding-top: 10px;

        // margin-bottom: 20px;
        li {
          line-height: 100%;
          margin-right: 25px;
          padding-bottom: 10px;
        }
      }
    }
  }
}

.others {
  width: 1200px;
  margin: 0 auto;

  ul {
    li {
      margin-bottom: 20px;


    }
  }
}
</style>
